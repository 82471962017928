@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=Nunito+Sans:opsz@6..12&display=swap');
*{
  font-family: 'Nunito Sans', sans-serif;
}
li,ul{
  list-style: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.company:hover h6{
  color: #fff !important;
}

.logincover h2 {
  font-family: 'Cormorant', serif;
  text-align: left;
  font-weight: 300;
  position: relative;
  font-style: italic;
  font-size: 70px;
  line-height: 85px;
}

.logincover h2::after {
  content: "";
  position: absolute;
  right: 70px;
  width: 226px;
  height: 14px;
  bottom: 0;
  background: url(./Images/underline.png);
}
.logincover h2 strong {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-family: 'Cormorant', serif;
}
.scrollbarThin{
  scrollbar-width: thin;
}

 /* Works on Chrome, Edge, and Safari */
 .scrollbarThin::-webkit-scrollbar {
  width: 4px;
}

.scrollbarThin::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

.scrollbarThin::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

.scrollbarThin {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrollbarThin::-webkit-scrollbar {
  width: 3px;
  height: 3px; /* The height is only seen on horizontal scrolling */
}

.scrollbarThin::-webkit-scrollbar-track {
  background: gray;
  padding: 2px;
}

.scrollbarThin::-webkit-scrollbar-thumb {
  background-color: #000;
}
.connectedLine,.connectedLineInactive{
  position: relative;
}
.connectedLine::after{
  content: '';
  height: 202px;
  width: 2px;
  position: absolute;
  background-color: #1e40af;
  transform: rotate(90deg);
  top: -33px;
  bottom: 0;
  right: -31px;
  margin: auto;
  z-index: 0;
}
.connectedLineInactive::after{
  content: '';
  height: 202px;
  width: 2px;
  position: absolute;
  background-color: #e2eaff;
  transform: rotate(90deg);
  top: -33px;
  bottom: 0;
  right: -31px;
  margin: auto;
  z-index: 0;
}
.editoption{
  display: none;
}
.showEditOption:hover .editoption{
  display: block !important;
}
.ql-snow {
  background-color: #fff;
}

@media only screen and (min-width:220px) and (max-width:991px)  {
  .connectedLineInactive::after{
    content: '';
    height: 202px;
    width: 2px;
    position: absolute;
    background-color: #e2eaff;
    transform: rotate(90deg);
    top: -33px;
    bottom: 0;
    right: -31px;
    margin: auto;
    z-index: 0;
    display: none;
  }
  .connectedLine::after{
    content: '';
    height: 202px;
    width: 2px;
    position: absolute;
    background-color: #1e40af;
    transform: rotate(90deg);
    top: -33px;
    bottom: 0;
    right: -31px;
    margin: auto;
    z-index: 0;
    display: none;
  }
}